// extracted by mini-css-extract-plugin
export var CarrouselWrapper2 = "NielsShoeMeulman-module--CarrouselWrapper2--nMSM0";
export var DescriptionWrapper = "NielsShoeMeulman-module--DescriptionWrapper--8utiL";
export var DownloaderWrapper = "NielsShoeMeulman-module--DownloaderWrapper--u6OW5";
export var EventWrapper = "NielsShoeMeulman-module--EventWrapper--LUHLB";
export var ImageWrapper = "NielsShoeMeulman-module--ImageWrapper--fBSXy";
export var ImagesWrapper = "NielsShoeMeulman-module--ImagesWrapper--ddKKZ";
export var MobileTile = "NielsShoeMeulman-module--MobileTile--+17rN";
export var PdpWrapper = "NielsShoeMeulman-module--PdpWrapper--QQyUs";
export var PhotosWrapper = "NielsShoeMeulman-module--PhotosWrapper--dyRCX";
export var TitleWrapper = "NielsShoeMeulman-module--TitleWrapper--GJsfQ";
export var Wrapper = "NielsShoeMeulman-module--Wrapper--v8Uoy";