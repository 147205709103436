import * as React from "react";
import {
  Wrapper,
  EventWrapper,
  PdpWrapper,
  DescriptionWrapper,
  DownloaderWrapper,
  PhotosWrapper,
CarrouselWrapper2,
  TitleWrapper,
  ImageWrapper,
  MobileTile
} from "./NielsShoeMeulman.module.css";
import { GrayTitle } from "./../../../../css/Title.module.css";
import Carrousel from "../../../../components/Carrousel";
import Title from "../../../../components/Title";
import Carousel from 'react-multi-carousel';

import Sho1 from "../../../../res/shoe/expo/shoe.jpg"
import Shoe1 from "../../../../res/shoe/expo/shoe1.jpg"
import Shoe2 from "../../../../res/shoe/expo/shoe2.jpg"
import Shoe3 from "../../../../res/shoe/expo/shoe3.jpg"
import Shoe4 from "../../../../res/shoe/expo/shoe4.jpg"
import Shoe5 from "../../../../res/shoe/expo/shoe5.jpg"
import Shoe6 from "../../../../res/shoe/expo/shoe6.jpg"
import Shoe7 from "../../../../res/shoe/expo/shoe7.jpg"
import PdfShoe from "../../../../res/shoe/presse.pdf"
import Tile from "../../../../res/shoe/expo/shoe-tile.png"

const isBrowser = typeof window !== "undefined";
const infos ={
  photos: [
    { src: Sho1, name: "Shoe" },
    { src: Shoe1, name: "Shoe" },
    { src: Shoe2, name: "Shoe" },
  ],
};

const NielsShoeMeulman = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div className={Wrapper}>
      <div className={EventWrapper}>
        <img className={PdpWrapper} src={Tile} alt="shoe"/>
        <div className={DescriptionWrapper}>
        <h1 className={TitleWrapper}>UNSTALLATION<h2 style={{paddingLeft: "16px"}}>NIELS SHOE MEULMAN - Marseille - 2019</h2></h1>
        <br />
        <img className={MobileTile} src={Tile} alt="shoe"/>
        <br />
        <p>
        Le 28 avril 2019, Ghost accueillera le vernissage de Unstallation, une exposition solo de l'artiste graffiti/street Niels Meulman, plus connu sous le nom de Shoe (Amsterdam, 1967). Dans les années 1980, Shoe a été personnellement introduit au mouvement artistique par les plus grands de New York, comme Dondi White, Rammellzee et Keith Haring. Niels Shoe Meulman est un artiste, graphiste et directeur artistique de renommée internationale originaire d'Amsterdam. Meulman a commencé à taguer Shoe en 1979 et est devenu une légende du graffiti à l'âge de 18 ans. Connu sous le nom de "Shoe", Meulman est un artiste visuel, connu pour ses peintures gestuelles qui révèlent des traces vives de graffiti et de calligraphie. Il a révolutionné l'art de l'écriture en lançant le mouvement Calligraffiti, affirmant qu'"un mot est une image et que l'écriture est une peinture".
          </p>
          <br></br>
          <br></br>
          <a className={DownloaderWrapper} href={PdfShoe}download rel="noopener noreferrer" target="_blank">
              Télécharger le communiqué de presse
          </a>
          <br />
        </div>
      </div>
        
        <div className={PhotosWrapper}>
          <Title title={"Vues in situ"} />
          <div className={CarrouselWrapper2}>
          <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos.map((image) => (
                <img
                  className={ImageWrapper}
                  key={image.name}
                  alt={image.name}
                  src={image.src}
                />
            ))}
          </Carousel>
          </div>
        </div>
        <br />
       
      {/* <div className={InfosWrapper}>
        <div className={DetailsWrapper}>
          <p className={SectionTitle}>Détails</p>
          <div className={LineWrapper}></div>
          <div className={RowWrapper}>
            <p>Date:</p>
            <div className={Spacer}></div>
            <p>Avril 2018</p>
          </div>
          <div className={PaddingTop10}></div>
          <div className={RowWrapper}>
            <p>Event category:</p>
            <div className={Spacer}></div>
            <p>Exhibitions</p>
          </div>
        </div>
        <div className={Spacer}></div>
        <div className={OrganisationWrapper}>
          <p className={SectionTitle}>Organisation</p>
          <div className={LineWrapper}></div>
          <div className={RowWrapper}>
            <div className={Spacer}></div>
            <p>Ghost Galerie</p>
          </div>
          <div className={PaddingTop10}></div>
          <div className={RowWrapper}>
            <p>Téléphone:</p>
            <div className={Spacer}></div>
            <a className={LinkWrapper} href="tel:+33633131465">
              +33(0)6.33.13.14.65
            </a>
          </div>
          <div className={PaddingTop10}></div>
          <div className={RowWrapper}>
            <p>Email:</p>
            <div className={Spacer}></div>
            <a className={LinkWrapper} href="mailto:contact@ghostgalerie.com">
              contact@ghostgalerie.com
            </a>
          </div>
        </div>
        <div className={Spacer}></div>
        <div className={AddressWrapper}>
          <p className={SectionTitle}>Adresse</p>
          <div className={LineWrapper}></div>
          <div className={RowWrapper}>
            <div className={Spacer}></div>
            <p>2 rue de Bélloi, 13006 Marseille</p>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default NielsShoeMeulman;
